import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideState, provideStore } from '@ngrx/store';
import { QuizFeature } from '@app/store/quiz/reducer';
import * as QuizEffects from './core/store/quiz/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { environment } from '../environments/environment';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localeData from 'dayjs/plugin/localeData';
import objectSupport from 'dayjs/plugin/objectSupport';
import duration from 'dayjs/plugin/duration';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AccessTokenInterceptorService } from '@app/services/access-token-interceptor/access-token-interceptor.service';
import { provideEffects } from '@ngrx/effects';
import { tokenProviders } from './app.token-providers';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(objectSupport);
dayjs.extend(duration);

const providers = [
  importProvidersFrom([
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ]),
  provideRouter(routes),
  provideStore({ router: routerReducer }),
  provideState(QuizFeature),
  provideEffects(QuizEffects),
  provideRouterStore(),
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AccessTokenInterceptorService,
    multi: true,
  },
  ...tokenProviders,
];

if (!environment.production) {
  providers.push(
    provideStoreDevtools({
      maxAge: 50,
      trace: false,
      traceLimit: 75,
      connectInZone: true,
    })
  );
}

export const appConfig: ApplicationConfig = {
  providers,
};
